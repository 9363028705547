import store from "../store";
import Steps from "../enums/WizzardSteps";
import { router } from "../main.js";
// import axios from "axios";
import { axiosInstance } from "../network/interceptors";
import ClientSteps from "../enums/ClientWizzardSteps";

export const isClient = () => {
  return store.getters["auth/getUser"].type == 2;
};
export const isTalent = () => {
  return store.getters["auth/getUser"].type == 1;
};

export const isLoggedIn = () => {
  return store.getters["auth/isLoggedIn"];
};

export const isWorkable = () => {
  return store.getters["auth/getUser"].from_workable == true;
};

export const isClientHasNomination = () => {
  return store.getters["auth/getClientNominationState"];
};

export const checkProperties = (obj) => {
  const isEmpty = !Object.values(obj).every(
    (value) => value !== null && value !== ""
  );
  return isEmpty;
};

export const getClientNominationState = async () => {
  return await store.dispatch("auth/getClientNominationState");
};

export const getProfileData = async (id) => {
  return await axiosInstance.get(`/api/client/users/${id}`);
};

export const isSubscribe = async (subscription) => {
  store
    .dispatch("talentProfile/subscription", subscription)
    .then(() => {
      localStorage.removeItem("redirect");
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const handleNavigationAfterAuth = async (user) => {
  if (user.type == 1) {
    if (user.step == Steps.PROFILE) {
      await store
        .dispatch("auth/setTalentHasContractRenewal")
        .then(() => {
          if (store.getters["auth/getTalentHasContractRenewal"]) {
            router.push({
              path: "/sq/talent/contracts-renewal",
            });
          } else {
            router.push({ path: "/" });
          }
        })
        .catch(() => {
          router.push({ path: "/" });
        });
    } else {
      router.push({ path: "/talent/Wizard" });
    }
  }

  if (user.type == 2) {
    if (user.step == ClientSteps.PROFILE) {
      await getClientNominationState().then(() => {
        if (isClientHasNomination()) {
          if (router.currentRoute.path != "/sq/client/nomination")
            router.push({
              path: `/sq/client/nomination`,
            });
        } else {
          store
            .dispatch("auth/setClientHasContractRenewal")
            .then(() => {
              if (store.getters["auth/getClientHasContractRenewal"]) {
                router.push({
                  path: "/sq/client/contracts-renewal",
                });
              } else {
                /*  if (router.currentRoute.path != "/sq/client/matching")
                  router.push({
                    path: "/sq/client/matching",
                  }); */
              }
            })
            .catch(() => {
              /*  if (router.currentRoute.path != "/sq/client/matching")
                router.push({
                  path: "/sq/client/matching",
                }); */
            });
        }
      });
    } else if (user.step == ClientSteps.DONE) {
      await getClientNominationState().then(() => {
        if (isClientHasNomination()) {
          if (router.currentRoute.path != "/sq/client/nomination")
            router.push({
              path: `/sq/client/nomination`,
            });
        } else {
          store
            .dispatch("auth/setClientHasContractRenewal")
            .then(() => {
              if (store.getters["auth/getClientHasContractRenewal"]) {
                router.push({
                  path: "/sq/client/contracts-renewal",
                });
              } else {
                /* if (router.currentRoute.path != "/sq/client/matching")
                  router.push({
                    path: "/sq/client/matching",
                  }); */
              }
            })
            .catch(() => {
              /*               if (router.currentRoute.path != "/sq/client/matching")
                router.push({
                  path: "/sq/client/matching",
                }); */
            });
        }
      });
    }
    if (
      store.getters["auth/getBookMeeting"] &&
      store.getters["auth/getUserStep"] !== ClientSteps.DONE
    ) {
      store.dispatch("auth/setStep", 5);
    } else if (Object.keys(store.getters["client/getStepOneData"]).length > 0) {
      store.dispatch("auth/setStep", 3);
      axiosInstance
        .post(
          "/api/client/projects/about",
          store.getters["client/getStepOneData"]
        )
        .then((res) => {
          if (
            res &&
            Object.keys(store.getters["client/getStepTwoData"]).length > 0
          ) {
            axiosInstance
              .post(
                "/api/client/projects/services",
                store.getters["client/getStepTwoData"]
              )
              .then(() => {});
          }
        });
    } else {
      if (isClientHasNomination()) {
        return router.push({
          path: `/sq/client/nomination`,
        });
      } else if (store.getters["auth/getClientHasContractRenewal"]) {
        return router.push({
          path: "/sq/client/contracts-renewal",
        });
      }

      return router.push({ path: "/client/wizard" });
    }
  }
};

export const handleAssessmentsListToFitInCarouselArr = (
  listArr,
  indexInpagination
) => {
  let assessmentsListUpdated = [];
  if (listArr?.data?.length > 0) {
    let list = listArr.data;
    let last_page = listArr.meta?.last_page;

    for (let i = 0; i < last_page; i++) {
      let ele = {
        assessment: {
          forRole: null,
          id: null,
          link: null,
          name: null,
          percentage: null,
          report: null,
          tech_stack: null,
        },
        id: null,
        name: null,
        type: null,
      };
      assessmentsListUpdated.push(ele);
    }
    if (assessmentsListUpdated?.length === last_page) {
      assessmentsListUpdated.splice(indexInpagination, 1, list[0]);
    }
  }
  return assessmentsListUpdated;
};
