import { axiosInstance } from "../../network/interceptors";

const state = {
  modalIsOpen: false,
  countryList: [],
  talentRolesList: [],
  isSkeleton: false,
  utmParameters: JSON.parse(localStorage.getItem("utmParameters")) || {},
};
const mutations = {
  moda_is_open(state, modalIsOpen) {
    state.modalIsOpen = modalIsOpen;
  },
  country_list(state, countryList) {
    state.countryList = countryList;
  },
  talent_roles_list(state, talentRolesList) {
    state.talentRolesList = talentRolesList;
  },
  is_skeleton(state, isSkeleton) {
    state.isSkeleton = isSkeleton;
  },
  setUTMParameters(state, utmParameters) {
    // set it to local storage utmParameters
    localStorage.setItem("utmParameters", JSON.stringify(utmParameters));
    state.utmParameters = utmParameters;
  },
};
const actions = {
  handleModalStatus({ commit }, data) {
    commit("moda_is_open", data);
  },
  handleSeletonStatus({ commit }, data) {
    commit("is_skeleton", data);
  },
  getCountriesRequest({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/staticContent/countries")
        .then((res) => {
          commit("country_list", res.data.data);
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },
  getTalentRolesRequest({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/staticContent/roles")
        .then((res) => {
          commit("talent_roles_list", res.data);
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },
};
const getters = {
  getModalStaus: (state) => state.modalIsOpen,
  getSkeletonStaus: (state) => state.isSkeleton,
  getCountryList: (state) => state.countryList,
  getTalentRolesList: (state) => state.talentRolesList,
  getUTMParameters: (state) => state.utmParameters,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
