/* eslint-disable no-unused-vars */
import { axiosInstance } from "./network/interceptors";
import Vue from "vue";
import VueAxios from "vue-axios";
import VueRouter from "vue-router";
import VueConfirmDialog from "vue-confirm-dialog";
import store from "./store";
import { routes } from "./routes/index";
import i18n from "./utils/i18n";
import Steps from "./enums/WizzardSteps";
import Tabs from "vue-material-tabs";
import clientSteps from "./enums/ClientWizzardSteps";
import VueSkeletonLoader from "skeleton-loader-vue";
import Loading from "vue-loading-overlay";
import Tooltip from "vue-directive-tooltip";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import VueMeta from "vue-meta";
import vSelect from "vue-select";
import * as Sentry from "@sentry/vue";

import("./assets/css/index.css");

function lazyLoadStyles() {
  import("vue-directive-tooltip/dist/vueDirectiveTooltip.css");
  import("vue-loading-overlay/dist/vue-loading.css");
  import("izitoast/dist/css/iziToast.min.css");
  import("animate.css");
  import("bootstrap");
  import("aos/dist/aos.css");
  import("vue-select/dist/vue-select.css");

  if (
    !document.querySelector(
      'link[href="https://unpkg.com/bootstrap-icons@1.7.2/font/bootstrap-icons.css"]'
    )
  ) {
    // Create a link element
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://unpkg.com/bootstrap-icons@1.7.2/font/bootstrap-icons.css";
    document.head.appendChild(link);
  }
}

Vue.use(VueAxios, axiosInstance);
Vue.use(VueMeta);
Vue.use(Tooltip);
Vue.use(Vuelidate);
Vue.use(VueConfirmDialog);
Vue.use(Tabs);
Vue.use(Loading, {
  color: "#FFC629",
  loader: "dots",
  width: 180,
  height: 150,
  opacity: 0.5,
});
Vue.component("skeleton-loader-vue", VueSkeletonLoader);
Vue.component("v-select", vSelect);

// Lazy load styles and scripts after the app is fully loaded
window.addEventListener("load", () => {
  Vue.nextTick(() => {
    lazyLoadStyles();
  });
});

Vue.config.baseurl = process.env.MIX_API_URL;

Vue.use(VueRouter);

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.performance = true;

export const router = new VueRouter({
  mode: "history",
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    if (window._conv_q) {
      window._conv_q.push(["run", "true"]);
    }

    document.title = to.meta.title || "Squadio";
    if (to.fullPath !== from.fullPath) {
      const utmParameters = extractUTMParameters(to.query);
      const currentUrl = window.location;
      let payload = {
        ...utmParameters,
        page_name: to.name,
        page_url: currentUrl.origin + to.fullPath,
      };

      // removeNullUndefined
      for (const key in payload) {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key];
        }
      }

      if (window.gtag) window.gtag("event", "page view", payload);
    }
  });
});

router.beforeEach((to, from, next) => {
  let language = localStorage.getItem("lang");
  if (!language) {
    language = "ar";
  }
  // set the current language for i18n.
  i18n.locale = language;
  axiosInstance.defaults.headers.common["Accept-Language"] = `${i18n.locale}`;

  localStorage.setItem("lang", i18n.locale);

  if (
    to.meta.authPage &&
    store.getters["auth/isLoggedIn"] &&
    !localStorage.getItem("is_one_step")
  ) {
    next(
      store.getters["auth/getUser"].type == 1 //talent
        ? store.getters["auth/getUserStep"] == Steps.PROFILE
          ? { name: "Profile" }
          : { name: "Wizard" }
        : store.getters["auth/getUser"].type == 2 //client
        ? store.getters["auth/getUserStep"] == clientSteps.PROFILE
          ? { name: "clientProfile" }
          : { name: "clientWizard" }
        : { name: "clientHome" }
    );
    return;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let query = to.query.verifyURL
      ? {
          verifyURL: to.query.verifyURL,
        }
      : {};

    if (to.name === "Nominated profiles" && to.query.ref) {
      localStorage.setItem("redirect", to.fullPath);
    }
    if (store.getters["auth/isLoggedIn"]) {
      to.name == "Wizard" && store.getters["auth/getUserStep"] == Steps.PROFILE
        ? next({
            name: "Profile",
            query: query,
          })
        : next();
      return;
    }

    next({
      name: "Login",
      query: { ...query, redirect: to.fullPath },
    });
  } else {
    if (
      !!localStorage.getItem("is_one_step") &&
      !store.getters["auth/isLoggedIn"] &&
      to.name == "clientWizard"
    ) {
      next(`/client-signup?campaign=15`);
      return;
    }
    next();
  }
});

const extractUTMParameters = (queryParams) => {
  let params = queryParams;

  let getUTMParameters = store.getters["shared/getUTMParameters"];

  const utmParameters = {
    utm_source: params.utm_source || getUTMParameters?.utm_source,
    utm_medium: params.utm_medium || getUTMParameters?.utm_medium,
    utm_campaign: params.utm_campaign || getUTMParameters?.utm_campaign,
    utm_term: params.utm_term || getUTMParameters?.utm_term,
    utm_content: params.utm_content || getUTMParameters?.utm_content,
    gclid: params.gclid || getUTMParameters?.gclid,
    dclid: params.dclid || getUTMParameters?.dclid,
    gbraid: params.gbraid || getUTMParameters?.gbraid,
    wbraid: params.wbraids || getUTMParameters?.wbraids,
    fbclid: params.fbclid || getUTMParameters?.fbclid,
    ttclid: params.ttclid || getUTMParameters?.ttclid,
    twclid: params.twclid || getUTMParameters?.twclid,
    li_fat_id: params.li_fat_id || getUTMParameters?.li_fat_id,
  };

  store.commit("shared/setUTMParameters", utmParameters);

  return utmParameters;
};

// for live will be .includes('production')
if (process.env.VUE_APP_AWS_BASE_URL.includes("production")) {
  const initializeSentry = () => {
    // Convert Experiences code
    const script = document.createElement("script");
    script.src = "//cdn-4.convertexperiments.com/v1/js/100410019-100411966.js";
    script.async = true;
    script.type = "text/javascript";
    document.head.appendChild(script);
    // Convert Experiences code

    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 0, //  Capture %0 of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  };
  window.addEventListener("load", () => {
    initializeSentry();
  });
}

/****************** App Instance ******************/
import { mapGetters } from "vuex";
const app = new Vue({
  el: "#app",
  router: router,
  i18n,
  store,
  render: (h) => h(App),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
    modalIsOpen() {
      return this.$store.getters["shared/getModalStaus"];
    },
  },
  watch: {
    modalIsOpen() {
      document.querySelector("body").style.overflowY = this.modalIsOpen
        ? "hidden"
        : "auto";
      document.querySelector("html").style.overflowY = this.modalIsOpen
        ? "hidden"
        : "auto";
    },
    user: {
      immediate: true,
      deep: true,
      handler() {
        if (
          !this.user ||
          !process.env.VUE_APP_AWS_BASE_URL.includes("production")
        ) {
          return;
        }

        // Hotjar Tracking Code for https://squadio.com

        // uuidv4 function to generate a random user ID
        const uuidv4 = () => {
          return "xxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 8) | 0,
              v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(8);
          });
        };

        // Set the user session ID in localStorage if it doesn't exist and get it if exists
        if (!localStorage.getItem("hjSessionId")) {
          // If it doesn't exist, generate a new session ID
          const hjSessionId = uuidv4();

          // Set the session ID in localStorage
          localStorage.setItem("hjSessionId", hjSessionId);
        }

        // Retrieve the user session ID from localStorage
        const hjSessionId = localStorage.getItem("hjSessionId");

        if (window.hj) {
          window.hj("identify", hjSessionId, {
            squadio_id: this.user.id,
            squadio_email: this.user.email,
          });
        }

        if (Sentry) {
          Sentry.setUser({
            "x-hotjar-session-id": hjSessionId,
            squadio_id: this.user.id,
            squadio_email: this.user.email,
          });
        }
      },
    },
  },

  //       watch: {
  //         modalIsOpen: {
  //      handler(newVal, oldVal){
  //        console.log(newVal, oldVal)
  //              document.querySelector('body').style.overflowY = this.modalIsOpen ? 'hidden' : 'auto';

  //      },
  //      deep: true
  //   }
  // }
});
/**************************************************/
